@import '~assets/styles/mixins';
@import '~assets/styles/variables';

button.btn_default {
  @include default-button;
  @include font-button;

  &.confirm {
    max-width: 200px;
    width: 100%;
    height: 44px;
    box-shadow: 2px 4px 4px rgba(33, 75, 165, 0.2);
    border-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: #94cd00;
    cursor: pointer;

    svg {
      margin-right: 10px;
    }

    &.disabled {
      border: none;
      background-color: $disabled;
      color: $text-light;
      cursor: default;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }

      &:active {
        background-color: $disabled;
      }
    }
  }
}
