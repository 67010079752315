@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.content {
	max-width: 912px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	margin: 0 auto;

	.linkBlock {
	  width: 100%;
    max-width: 500px;
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;

		& > a {
			font-size: 12px;
		}
	}

	.createAccount {
		&__link,
		&__link:visited {
			display: block;
			color: $text-light;
			line-height: 20px;
			// padding: 5px;
		}
	}

	&__greeting {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		background: $gradients-orange;
		box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
		border-radius: 10px 10px 0 0;
		padding-top: 5vh;

		.greeting {
			width: 100%;
			height: 100%;

			&__image {
				max-width: 300px;
				max-height: 380px;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			&__title {
				margin-top: 8.5vh;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 20px;
				margin-bottom: 4.9vh;
			}
		}
	}
}

.container {
	max-width: 260px;
	width: 100%;

	a {
		display: block;
		text-decoration: none;

		&:first-child {
			margin-bottom: 20px;
		}
	}
}
