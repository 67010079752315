@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.container {
  @include changeOpacityToVisibleAnimation;
  max-width: 912px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 10px;
  padding-top: 5vh;
  padding-bottom: 3.5vh;
  margin: 0 auto;

  &.heightDisable {
    min-height: auto;
  }

  &.fullHeight {
    height: 100%;
  }

  &.isHeightVisibility {
    height: calc(100vh - 140px);
  }

  &.styleTable {
    height: 100%;
    padding-top: 2vh;
    padding-bottom: 0;
  }

  &.paddingTopDisabled {
    padding-top: 0px;
  }

  &__gradient {
    border-radius: 10px 10px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 73%;
    background: $gradients-orange;
  }

  &__image {
    @include changeOpacityToVisibleAnimation;
    width: 100%;
    max-height: 300px;
  }

  > * {
    z-index: 10;
  }
}

.congrats {
  max-width: 1494px;
  width: 100%;
}
