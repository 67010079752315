@import '~assets/styles/mixins';

.main {
  @include inputContainer;

  .label_bold {
    @include popupLabel;
  }
}

.height_small {
  height: 60px;
}

.margin_disabled {
  margin-bottom: 0;
}

.signUp {
  @include inputContainer;
}

.uploadLogo {
  width: 100%;
  height: 138px;
}

.textArea {
  height: 180px;
}

.disableHeight {
  height: auto;
  min-height: unset;
}

.disable {
  pointer-events: none;
}

.margin_bottom_small {
  margin-bottom: 4px;
}

.small_label {
  font-size: 14px;
  line-height: 15px;
  color: #1c2f4c;
  margin-bottom: 8px;
  font-weight: 700;
}

.label_required {
  color: #D00074;
}

.ml_26 {
  margin-left: 26px;
}

.mb_12 {
  margin-bottom: 8px;
}
